<template>
	<div v-if="choiceSelected !== ''" style="position: absolute; width: 300px; top: 100px; z-index: 4000; right: 15px">
		<b-card :title="chartSelected.title">
			<span style="position: absolute; z-index: 4001; right: 21px; top: 20px; cursor: pointer" @click="handleClickCloseDialog">
				<i class="fa fa-times" aria-hidden="true" />
			</span>
			<b-card-text>
				<b-row v-if="!isAddDependencies">
					<b-col>
						<b-button variant="primary" block @click="addDependencies">
							{{ FormMSG(1, 'Dependencies') }}
						</b-button>
					</b-col>
				</b-row>
				<p class="mt-2" v-if="isAddDependencies">{{ FormMSG(2, 'Dependencies') }}</p>
				<div v-if="isAddDependencies">
					<b-row v-for="(dependence, index) in dependencies" :key="index" class="mb-2">
						<b-col>
							<span class="btn btn-warning btn-block" @click="handleClickDependencies(dependence)">
								<span style="display: inline-block">
									{{ dependence.title }}
								</span>
								<span style="display: inline-block; float: right">
									<i class="fa fa-trash" />
								</span>
							</span>
						</b-col>
					</b-row>
					<b-row v-if="dependencies.length === 0">
						<b-col>
							{{ FormMSG(3, 'No dependencies') }}
						</b-col>
					</b-row>
				</div>
			</b-card-text>
		</b-card>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
export default {
	name: 'Dependencies',

	mixins: [languageMessages],

	props: {
		choiceSelected: { type: String, default: '' },
		chartSelected: { type: Object, default: () => {} },
		tasks: { type: Array, default: () => [] }
	},

	data() {
		return {
			dependencies: [],
			isAddDependencies: false
		};
	},

	watch: {
		'chartSelected.dependentOn'(val) {
			this.dependencies = [];
			if (val.length > 0) {
				val.forEach((v) => {
					const findIndex = this.tasks.findIndex((t) => parseInt(t.id) === parseInt(v));
					if (findIndex !== -1) {
						this.dependencies.push(this.tasks[findIndex]);
					}
				});
			}
		}
	},

	methods: {
		addDependencies() {
			this.isAddDependencies = true;
			this.$emit('dependencies:add');
		},
		handleClickCloseDialog() {
			this.isAddDependencies = false;
			this.$emit('dependencies:close');
		},
		handleClickDependencies(payload) {
			this.$emit('dependencies:remove', {
				id: parseInt(payload.id)
			});
		}
	}
};
</script>

<style scoped></style>
