var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.choiceSelected !== ""
    ? _c(
        "div",
        {
          staticStyle: {
            position: "absolute",
            width: "300px",
            top: "100px",
            "z-index": "4000",
            right: "15px",
          },
        },
        [
          _c(
            "b-card",
            { attrs: { title: _vm.chartSelected.title } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    position: "absolute",
                    "z-index": "4001",
                    right: "21px",
                    top: "20px",
                    cursor: "pointer",
                  },
                  on: { click: _vm.handleClickCloseDialog },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-times",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              ),
              _c(
                "b-card-text",
                [
                  !_vm.isAddDependencies
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "primary", block: "" },
                                  on: { click: _vm.addDependencies },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t" +
                                      _vm._s(_vm.FormMSG(1, "Dependencies")) +
                                      "\n\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isAddDependencies
                    ? _c("p", { staticClass: "mt-2" }, [
                        _vm._v(_vm._s(_vm.FormMSG(2, "Dependencies"))),
                      ])
                    : _vm._e(),
                  _vm.isAddDependencies
                    ? _c(
                        "div",
                        [
                          _vm._l(
                            _vm.dependencies,
                            function (dependence, index) {
                              return _c(
                                "b-row",
                                { key: index, staticClass: "mb-2" },
                                [
                                  _c("b-col", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "btn btn-warning btn-block",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleClickDependencies(
                                              dependence
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              display: "inline-block",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t" +
                                                _vm._s(dependence.title) +
                                                "\n\t\t\t\t\t\t\t"
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              display: "inline-block",
                                              float: "right",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-trash",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              )
                            }
                          ),
                          _vm.dependencies.length === 0
                            ? _c(
                                "b-row",
                                [
                                  _c("b-col", [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(3, "No dependencies")
                                        ) +
                                        "\n\t\t\t\t\t"
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }