<template>
	<b-modal header-class="header-class-modal-doc-package" id="modal-task-planning" v-model="isOpen" hide-footer @hidden="emitCloseEvent">
		<template #modal-title>
			{{ selectedEvent.title }}
		</template>
		<b-form>
			<b-row class="mb-3">
				<b-col cols="6">
					<change-status-planning :status-id="dataToEdit.status" @status:selected="selectStatus" />
				</b-col>
				<b-col cols="6">
					<div class="float-right">
						<b-form-checkbox
							id="checkbox-1"
							v-model="dataToEdit.putInPlanning"
							name="checkbox-1"
							:value="dataToEdit.putInPlanning"
							:unchecked-value="false"
						>
							Put in planning
						</b-form-checkbox>
					</div>
				</b-col>
			</b-row>

			<b-form-group :label="FormMSG(1, 'Priority')" label-for="Priority" :label-cols="3" :horizontal="true">
				<b-form-select v-model="dataToEdit.priority" :options="priorities"></b-form-select>
			</b-form-group>
			<b-form-group :label="FormMSG(2, 'Duration')" label-for="Duration" :label-cols="3" :horizontal="true">
				<div class="d-flex row">
					<div class="col-6">
						<div class="d-flex row">
							<div class="col-12">
								<div class="d-flex flex-row">
									<b-form-input v-if="isProd" v-model="dataToEdit.dayDuration" type="number" step="1" placeholder="1" :disabled="!isProd" />
									<b-form-input v-else :value="dataToEdit.dayDuration" type="number" step="1" placeholder="1" :disabled="true" />
									<div class="ml-2 pt-2 task-label text-success font-weight-bold">
										{{ FormMSG(3, 'Days') }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-6">
						<div class="d-flex row">
							<div class="col-12">
								<div class="d-flex flex-row">
									<b-form-input v-model="dataToEdit.duration" type="time" />
									<div class="ml-2 pt-2 task-label text-success font-weight-bold">
										{{ FormMSG(4, 'Hours/d') }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</b-form-group>
			<b-row>
				<b-col>
					<fieldset class="scheduler-border">
						<legend class="scheduler-border">{{ FormMSG(5, 'Desired') }}</legend>
						<b-form-group class="bootstrap-date-selection" label="Start Date" label-for="DesiredDate" :label-cols="2" :horizontal="true">
							<b-row>
								<b-col cols="8">
									<b-form-datepicker v-model="dataToEdit.desiredStartDate" label-no-date-selected="No date selected" :locale="currentLang" />
								</b-col>
								<b-col cols="4">
									<b-form-timepicker v-model="dataToEdit.desiredStartHours" type="text" required placeholder="No time selected" />
								</b-col>
							</b-row>
						</b-form-group>
					</fieldset>
				</b-col>
			</b-row>
			<b-row class="mt-3">
				<b-col>
					<div class="float-right">
						<b-button type="button" :disabled="formBusy" @click="hideModal" class="mr-2">
							{{ FormMSG(6, 'Close') }}
						</b-button>
						<b-button variant="success" type="button" :disabled="formBusy" @click="save">
							<span v-if="formBusy">{{ FormMSG(7, 'Modification in progress') }}</span>
							<span v-else>{{ FormMSG(8, 'Save') }}</span>
						</b-button>
					</div>
				</b-col>
			</b-row>
		</b-form>
	</b-modal>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import moment from 'moment';
import { updatedTask, setDesiredStartTask } from '@/cruds/task.crud';
import dateMixin from '@/mixins/date.mixin';
import { store } from '@/store';
import ChangeStatusPlanning from '@/components/Plannings/ChangeStatusPlanning';

export default {
	name: 'ModalGantt',

	components: {
		ChangeStatusPlanning
	},

	mixins: [languageMessages, GlobalMixin, dateMixin],

	props: {
		open: { type: Boolean, default: false },
		selectedEvent: { type: Object, default: null },
		tasks: { type: Array, default: () => [] }
	},

	data() {
		return {
			formBusy: false,
			dataToEdit: {
				status: null,
				priority: null,
				dayDuration: null,
				duration: null,
				desiredStartDate: null,
				desiredStartHours: null,
				putInPlanning: false
			},
			sendAndPlanned: false
		};
	},

	computed: {
		isProd() {
			return store.isProd();
		},
		isAdmin() {
			return store.isAdmin();
		},
		isOpen: {
			get() {
				return this.open;
			},
			set() {
				return this.open;
			}
		},
		priorities() {
			return this.FormMenu(1123);
		},
		statues() {
			return this.FormMenu(1122);
		}
	},

	watch: {
		selectedEvent(val) {
			if (val !== null) {
				const desiredStart = new Date(val.desiredStart);
				this.dataToEdit.status = val.status;
				this.dataToEdit.priority = val.priority;
				this.dataToEdit.dayDuration = val.dayDuration;
				this.dataToEdit.duration = moment(val.timeDuration).utc().format('HH:mm');
				this.dataToEdit.desiredStartDate = moment(desiredStart).utc().format('YYYY-MM-DD');
				this.dataToEdit.desiredStartHours = moment(desiredStart).utc().format('HH:mm');
				this.dataToEdit.putInPlanning = val.putInPlanning;
			}
		}
	},

	methods: {
		selectStatus({ value }) {
			this.dataToEdit.status = value;
		},
		emitCloseEvent() {
			this.$emit('modal:close');
		},
		async save() {
			await this.confirmModal(this.sendData, this.FormMSG(10, 'Are you sure ?'));
		},
		preparePayloadEmit() {
			return {
				priority: parseInt(this.dataToEdit.priority),
				dayDuration: parseInt(this.dataToEdit.dayDuration),
				duration: this.convertTimeToMinute(`0001-01-01T${this.dataToEdit.duration}:00Z`),
				desiredStart: `${this.dataToEdit.desiredStartDate}T${this.dataToEdit.desiredStartHours}:00Z`,
				durationString: this.convertTimeToString(`0001-01-01T${this.dataToEdit.duration}:00Z`, this.dataToEdit.dayDuration)
			};
		},
		hideModal() {
			this.emitCloseEvent();
			this.sendAndPlanned = false;
			this.$bvModal.hide('modal-task-planning');
		},
		async sendData() {
			this.formBusy = true;
			let inputUpdatedTask = {};
			const desiredStart = new Date(`${this.dataToEdit.desiredStartDate} ${this.dataToEdit.desiredStartHours}`);

			inputUpdatedTask.priority = parseInt(this.dataToEdit.priority, 10);
			inputUpdatedTask.dayDuration = parseInt(this.dataToEdit.dayDuration, 10);
			inputUpdatedTask.duration = new Date(`0001-01-01T${this.dataToEdit.duration}:00Z`);
			inputUpdatedTask.desiredStart = desiredStart;
			inputUpdatedTask.taskPlanningId = this.selectedEvent.taskPlanningId;
			inputUpdatedTask.status = this.dataToEdit.status;
			inputUpdatedTask.putInPlanning = this.dataToEdit.putInPlanning;

			await updatedTask(this.selectedEvent.id, inputUpdatedTask);
			await setDesiredStartTask(this.selectedEvent.id, desiredStart);
			this.$emit('task:save', {
				...this.selectedEvent,
				...this.preparePayloadEmit()
			});

			this.formBusy = false;
			this.hideModal();
		}
	}
};
</script>

<style scoped></style>
