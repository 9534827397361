<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<b-card no-body>
				<b-card-header>
					<b-row>
						<b-col cols="6">
							<h4>{{ FormMSG(1, 'Diagramme of GANTT') }}</h4>
						</b-col>
						<b-col cols="6">
							<router-link to="/plannings" tag="button" class="btn btn-success float-right">
								<i class="fa fa-calendar" aria-hidden="true" />
								&nbsp;{{ FormMSG(3, 'Plannings') }}
							</router-link>
						</b-col>
					</b-row>
				</b-card-header>
				<b-card-body>
					<b-row>
						<b-col>
							<dependencies
								:choice-selected="choiceSelected"
								:chart-selected="chartSelected"
								:tasks="tasks"
								@dependencies:add="handleDependenciesAdd"
								@dependencies:remove="handleDependenciesRemove"
								@dependencies:close="handleDependenciesClose"
							/>
							<div v-if="showPopper" style="position: absolute; z-index: 4000" :style="`left: ${positionX}px; top: ${positionY}px`">
								{{ FormMSG(2, 'Diagramme of Gantt') }}
							</div>
							<gantt-elastic
								:options="options"
								:tasks="tasks"
								@tasks-changed="tasksUpdate"
								@options-changed="optionsUpdate"
								@dynamic-style-changed="styleUpdate"
								@chart-milestone-click="handleChartTaskClick"
								@taskList-milestone-click="handleTaskListTaskClick"
							>
								<gantt-header slot="header"></gantt-header>
							</gantt-elastic>
							<modal-gantt
								:open="showModal"
								:selected-event="chartSelected"
								:tasks="tasks"
								@modal:close="handleModalGanttClose"
								@task:save="handleTaskSave"
							/>
						</b-col>
					</b-row>
				</b-card-body>
			</b-card>
		</transition>
	</errorContainer>
</template>

<script>
import GanttElastic from 'gantt-elastic';
import GanttHeader from 'gantt-elastic-header';
import dayjs from 'dayjs';
import { getTasks, addTaskConstraint, delTaskConstraint } from '@/cruds/task.crud';
import ModalGantt from '@/components/Gantt/ModalGantt';
import Dependencies from '@/components/Gantt/Dependencies';
import dateMixin from '@/mixins/date.mixin';
import languageMessages from '@/mixins/languageMessages';
import moment from 'moment';

const colorStatus = ['#d3d3d3', '#ffcc00', '#ff540d', '#2ecd6f', '#ff9900', '#f81c07', '#ff00df', '#a875ff', '#6bc950'];

export default {
	name: 'Gantt',

	components: {
		GanttElastic,
		GanttHeader,
		ModalGantt,
		Dependencies
	},

	mixins: [dateMixin, languageMessages],

	data() {
		return {
			erreur: {},
			warning: '',
			tasks: [],
			dynamicStyle: {},
			lastId: 16,
			showModal: false,
			showModalChoice: false,
			chartSelected: null,
			choiceSelected: '',

			positionX: 0,
			positionY: 0,
			showPopper: false
		};
	},
	computed: {
		options: {
			get() {
				return {
					taskMapping: {
						progress: 'percent'
					},
					maxRows: 100,
					maxHeight: 500,
					title: {
						label: 'Your project title as html (link or whatever...)',
						html: false
					},
					row: {
						height: 24
					},
					calendar: {
						hour: {
							display: false
						}
					},
					chart: {
						progress: {
							bar: false
						},
						expander: {
							display: true
						}
					},
					taskList: {
						expander: {
							straight: false
						},
						columns: [
							{
								id: 1,
								label: 'Description',
								value: 'description',
								width: 200,
								expander: true,
								html: true
							},
							{
								id: 3,
								label: 'Start',
								value: (task) => dayjs(task.start).format('YYYY-MM-DD'),
								width: 78
							},
							{
								id: 5,
								label: '%',
								value: 'progress',
								width: 35,
								style: {
									'task-list-header-label': {
										'text-align': 'center',
										width: '100%'
									},
									'task-list-item-value-container': {
										'text-align': 'center',
										width: '100%'
									}
								}
							}
						]
					},
					locale: {
						name: 'en',
						Now: 'Now',
						'X-Scale': 'Zoom-X',
						'Y-Scale': 'Zoom-Y',
						'Task list width': 'Task list',
						'Before/After': 'Expand',
						'Display task list': 'Task list'
					}
				};
			},
			set(val) {
				return val;
			}
		}
	},
	async mounted() {
		await this.getTasks();
	},
	methods: {
		async handleTaskSave(payload) {
			this.tasks = [];
			await this.getTasks();
			/* this.tasks.map((task) => {
        if (parseInt(task.id) === parseInt(payload.id)) {
          const dayDuration = parseInt(payload.dayDuration)
          task.priority = payload.priority
          task.dayDuration = dayDuration
          task.duration = dayDuration * 24 * 60 * 60 * 1000
          task.start = this.getDate(payload.desiredStart)
        }
      }) */
		},
		async handleDependenciesRemove({ id }) {
			await delTaskConstraint(this.chartSelected.id, id);
			this.chartSelected.dependentOn = this.chartSelected.dependentOn.filter((dO) => dO !== id);
		},
		handleDependenciesAdd() {
			this.choiceSelected = 'add';
		},
		handleDependenciesClose() {
			this.choiceSelected = '';
			this.chartSelected = { dependentOn: [] };
		},
		handleGanttChoiceSelected(payload) {
			if (payload.choice && payload.choice === 'add') {
				this.choiceSelected = payload.choice;
			}
		},
		handleModalGanttChoiceClose() {
			this.showModalChoice = false;
		},
		handleModalGanttClose() {
			this.showModal = false;
		},
		handleTaskListTaskClick({ column, data }) {
			this.showModal = true;
			this.chartSelected = data;
		},
		async handleChartTaskClick({ data }) {
			if (this.choiceSelected === '') {
				// this.showModalChoice = true
				this.choiceSelected = 'wait';
				this.chartSelected = data;
			} else if (this.choiceSelected === 'add') {
				if (parseInt(this.chartSelected.id) !== parseInt(data.id)) {
					const findIndex = this.chartSelected.dependentOn.findIndex((dO) => parseInt(dO) === parseInt(data.id));
					if (findIndex === -1) {
						await addTaskConstraint({
							taskId: parseInt(this.chartSelected.id),
							waitForTaskId: parseInt(data.id)
						});
						this.chartSelected.dependentOn.push(parseInt(data.id));
					}
				}
			} else if (this.choiceSelected === 'remove') {
				this.chartSelected.dependentOn = this.chartSelected.dependentOn.filter((dO) => dO !== parseInt(data.id));
			}
		},
		async getTasks() {
			this.$loading(true);
			const responses = await getTasks();
			const tasks = this.deduplicate(responses);

			for (let i = 0; i < tasks.length; i++) {
				const result = {};
				const task = tasks[i];
				if (task.desiredStart.split('T')[0] !== '0001-01-01') {
					let end = await this.addDateAndTime(task.desiredStart, 0, task.duration);
					let start = await this.addDateAndTime(task.desiredStart, 0, '0001-01-01T00:00:00Z');
					end = new Date(end);
					start = new Date(start);
					// const end = new Date(task.desiredStart)
					result.id = parseInt(task.id);
					result.title = task.name;
					result.label = task.name;
					result.description = `<span style="font-weight: bolder">${task.name}</span>`;
					result.start = start.getTime();
					result.end = end.getTime();
					result.status = task.status;
					result.priority = task.priority;
					result.taskType = task.type;
					result.desiredStart = task.desiredStart;
					result.dayDuration = task.dayDuration;
					result.timeDuration = task.duration;
					result.taskPlanningId = task.taskPlanningId;
					result.putInPlanning = task.putInPlanning;
					result.durationString = this.convertTimeToString(task.duration, task.dayDuration);
					if (parseInt(task.parentTaskId) > 0) {
						result.description = `${task.name}`;
						result.parentId = parseInt(task.parentTaskId);
					}
					result.collapsed = false;
					result.style = {
						base: {
							fill: colorStatus[task.status],
							stroke: colorStatus[task.status]
						},
						'tree-row-bar': {
							fill: '#1EBC61',
							stroke: '#0EAC51'
						},
						'tree-row-bar-polygon': {
							stroke: '#0EAC51'
						}
					};
					result.duration = task.dayDuration * 24 * 60 * 60 * 1000;
					result.percent = task.percentCompleted;
					result.type = 'milestone';
					result.dependentOn = [];
					if (task.taskConstraint.length > 0) {
						for (const constraint of task.taskConstraint) {
							result.dependentOn.push(parseInt(constraint.waitForTaskId));
						}
					}

					this.tasks.push(result);
				}
			}
			this.$loading(false);
		},
		deduplicate(data) {
			if (data.length > 0) {
				let result = [];

				data.forEach(function (elem) {
					const findIndex = result.findIndex((r) => r.id === elem.id && r.name === elem.name);
					if (findIndex === -1) {
						result.push(elem);
					}
				});

				return result;
			}
		},
		getDesiredStartToDate(desiredStart) {
			const dStart = new Date(desiredStart.split('T')[0] + 'T00:00:00Z');
			const currentDate = new Date(dStart).getTime();

			return currentDate;
		},
		tasksUpdate(tasks) {
			this.tasks = tasks;
		},
		optionsUpdate(options) {
			this.options = options;
		},
		styleUpdate(style) {
			this.dynamicStyle = style;
		}
	}
};
</script>

<style>
.gantt-elastic__header-title {
	opacity: 0;
}
</style>
