var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c(
        "transition",
        { attrs: { name: "slide" } },
        [
          _c(
            "b-card",
            { attrs: { "no-body": "" } },
            [
              _c(
                "b-card-header",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { cols: "6" } }, [
                        _c("h4", [
                          _vm._v(_vm._s(_vm.FormMSG(1, "Diagramme of GANTT"))),
                        ]),
                      ]),
                      _c(
                        "b-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "btn btn-success float-right",
                              attrs: { to: "/plannings", tag: "button" },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-calendar",
                                attrs: { "aria-hidden": "true" },
                              }),
                              _vm._v(
                                "\n\t\t\t\t\t\t\t " +
                                  _vm._s(_vm.FormMSG(3, "Plannings")) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-card-body",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c("dependencies", {
                            attrs: {
                              "choice-selected": _vm.choiceSelected,
                              "chart-selected": _vm.chartSelected,
                              tasks: _vm.tasks,
                            },
                            on: {
                              "dependencies:add": _vm.handleDependenciesAdd,
                              "dependencies:remove":
                                _vm.handleDependenciesRemove,
                              "dependencies:close": _vm.handleDependenciesClose,
                            },
                          }),
                          _vm.showPopper
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    position: "absolute",
                                    "z-index": "4000",
                                  },
                                  style: `left: ${_vm.positionX}px; top: ${_vm.positionY}px`,
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(2, "Diagramme of Gantt")
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "gantt-elastic",
                            {
                              attrs: { options: _vm.options, tasks: _vm.tasks },
                              on: {
                                "tasks-changed": _vm.tasksUpdate,
                                "options-changed": _vm.optionsUpdate,
                                "dynamic-style-changed": _vm.styleUpdate,
                                "chart-milestone-click":
                                  _vm.handleChartTaskClick,
                                "taskList-milestone-click":
                                  _vm.handleTaskListTaskClick,
                              },
                            },
                            [
                              _c("gantt-header", {
                                attrs: { slot: "header" },
                                slot: "header",
                              }),
                            ],
                            1
                          ),
                          _c("modal-gantt", {
                            attrs: {
                              open: _vm.showModal,
                              "selected-event": _vm.chartSelected,
                              tasks: _vm.tasks,
                            },
                            on: {
                              "modal:close": _vm.handleModalGanttClose,
                              "task:save": _vm.handleTaskSave,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }